import type { BaseData, IMainSubStructureModel, NormalProgrammeParams } from '../../utils';
import { request, SearchListModal } from '../../utils';
import { observable } from 'mobx';
import { DownOutlined, SmileOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Switch, Modal, message, Dropdown, Space } from 'antd';
import React from 'react';
import AdminEditorModel from './modal/model';
import ResetPasswordStore from './modal/resetPasswordModel';
import { API } from './api';
import dayjs from 'dayjs';

const { confirm } = Modal;

export default class Model {
  constructor() {
    this.pageStore.grid.onQuery();
  }

  public editorStore = new AdminEditorModel({ parent: this });

  public resetPasswordStore = new ResetPasswordStore({ parent: this });

  // 下拉菜单项
  private MenuItems: MenuProps['items'] = [
    {
      key: 'resetPasswordStore',
      label: '重置密码',
    },
  ];

  @observable public filterSet: Partial<NormalProgrammeParams> = {
    filterItems: [
      {
        labelWidth: 40,
        type: 'input',
        field: 'nickName',
        label: '昵称',
        placeholder: '请输入',
      },
      {
        type: 'input',
        field: 'userName',
        label: '用户名',
        placeholder: '请输入',
      },
      {
        type: 'input',
        field: 'mobile',
        label: '手机号',
        placeholder: '请输入',
      },
      {
        type: 'dateRange',
        field: 'createTime',
        label: '创建时间',
        placeholder: ['请选择', '请选择'],
      },
      {
        type: 'select',
        field: 'accountStatus',
        label: '账号状态',
        data: [
          {
            value: '',
            label: '全部',
          },
          {
            value: '1',
            label: '启用',
          },
          {
            value: '0',
            label: '禁用',
          },
        ],
      },
    ],
  };

  public grid: IMainSubStructureModel = {
    buttons: [
      {
        text: '删除',
        handleClick: () => {
          const ids = Array.from(this.pageStore.grid.gridModel.selectedIds);
          if (!ids.length) {
            message.warning('请选择要删除的用户');
            return;
          }
          confirm({
            title: '删除提示',
            content: '是否删除这些用户吗？',
            okText: '确定',
            cancelText: '取消',
            onOk: () => {
              this.requestDelete(ids.join(','));
            },
          });
        },
      },
      {
        text: '新增',
        handleClick: () => {
          this.editorStore.onShow();
        },
      },
    ],
    grid: {
      columns: [
        {
          key: 'userId',
          name: '操作',
          width: 200,
          formatter: ({ row }) => (
            <div>
              <Button
                onClick={() => {
                  console.log(row);

                  this.editorStore.onShow(row, 'edit');
                }}
                type="link"
              >
                修改
              </Button>
              <Button
                onClick={() => {
                  this.onDelete(row);
                }}
                type="link"
              >
                删除
              </Button>
              <Dropdown
                menu={{
                  items: this.MenuItems,
                  selectable: true,
                  onClick: (e) => {
                    this.onDropdownMenuChange(e, row);
                  },
                }}
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space>
                    更多
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </div>
          ),
        },
        {
          key: 'id',
          name: '用户编号',
        },
        {
          key: 'userName',
          name: '用户名',
        },
        {
          key: 'nickName',
          name: '用户昵称',
        },
        // {
        //   key: 'userRole',
        //   name: '角色',
        // },
        {
          key: 'mobile',
          name: '手机号',
        },
        {
          key: 'accountStatus',
          name: '启用状态',
          formatter: ({ row }) => (
            <div style={{ textAlign: 'left' }}>
              <Switch
                checked={row.accountStatus === 1}
                checkedChildren="启用"
                unCheckedChildren="禁用"
                onChange={() => {
                  this.onSwitchChange(row);
                }}
              />
            </div>
          ),
        },
        {
          key: 'createTime',
          name: '创建时间',
          formatter: ({ row }) => dayjs(row.createTime).format('YYYY-MM-DD HH:mm:ss'),
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      showPager: true,
      showGridOrderNo: false,
    },
    hiddenSubTable: true,
    api: {
      onQuery: (params) => {
        const { filterParams, ...rest } = params;
        const postParams = {
          ...filterParams,
          ...rest,
        };

        for (const k in postParams) {
          if (Array.isArray(postParams[k])) {
            postParams[k] = postParams[k].toString();
          }
        }
        if (postParams.createTime) {
          postParams.createTimeBegin = postParams.createTime.split(',')[0];
          postParams.createTimeEnd = postParams.createTime.split(',')[1];
          delete postParams.createTime;
        }
        return request<BaseData<any>>({
          url: API.queryAdminPage,
          method: 'POST',
          data: { ...postParams },
        });
      },
    },
  };

  public pageStore = new SearchListModal({
    programme: this.filterSet,
    grid: this.grid,
  });

  // 状态变更启用|禁用
  private onSwitchChange = async (row) => {
    row.accountStatus = row.accountStatus === 1 ? 0 : 1;
    const result = await request<{ code: number; msg?: string; data: any }>({
      url: API.editAdminStatus,
      method: 'POST',
      data: row,
    });
    message.success(result.msg);
    this.pageStore.grid.onQuery();
  };

  // 删除表格项
  private onDelete = (row) => {
    confirm({
      title: '删除提示',
      content: '是否删除该用户？',
      okText: '确定',
      cancelText: '取消',
      onOk: () => {
        this.requestDelete(row.id);
      },
    });
  };

  // 下拉菜单变更
  private onDropdownMenuChange = (e, row) => {
    this[e.key].onShow(row);
  };

  // 请求删除接口
  private requestDelete = async (ids) => {
    const result = await request<{ code: number; msg?: string; data: any }>({
      url: API.deleteAdmin,
      method: 'POST',
      data: { ids },
    });
    message.success(result.msg || '删除成功');
    this.pageStore.grid.onQuery();
  };
}
