import type { FormInstance } from 'antd';
import { message } from 'antd';
import type { BaseData } from '../../../utils';
import { request } from '../../../utils';
import { action, observable } from 'mobx';
import { API } from '../api';

export default class ResetPasswordStore {
  @observable
  public visible = false;

  @observable
  public saveLoading = false; // 保存状态

  @observable public ref: FormInstance;

  @observable public id = null;

  @observable public userName = null;

  @observable public parent = null;

  constructor({ parent }) {
    this.parent = parent;
  }

  @action
  public setRef = (ref: FormInstance): void => {
    this.ref = ref;
  };

  @action
  public onShow = (data?: any) => {
    this.visible = true;
    this.id = data.id;
    this.userName = data.userName;
  };

  @action
  public onClose = () => {
    this.ref.resetFields();
    this.visible = false;
  };

  @action
  public onSave = async () => {
    const isValidated = await this.ref.validateFields();
    if (!isValidated) {
      return;
    }
    this.saveLoading = true;
    const values = this.ref.getFieldsValue();
    const postParams = {
      ...values,
      id: this.id,
      userName: this.userName,
    };
    request<{ code: number; msg?: string; data: any }>({
      url: API.resetPassword,
      method: 'POST',
      data: postParams,
    })
      .then((result) => {
        console.log(result);
        this.ref.resetFields();
        message.success(result.data || '修改密码成功');
        this.onClose();
        this.parent.pageStore.grid.onQuery();
      })
      .finally(() => {
        this.saveLoading = false;
      });
  };
}
