import { SearchListStructure } from '../../utils/searchListStructure';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import Model from './model';
import { AdminEditorModal } from './modal';
import { ResetPasswordModal } from './modal/resetPasswordModal';

const Administrators = () => {
  const store = useMemo(() => new Model(), []);
  const { pageStore, editorStore, resetPasswordStore } = store;
  return (
    <div>
      <SearchListStructure store={pageStore} />
      <AdminEditorModal store={editorStore} />
      <ResetPasswordModal store={resetPasswordStore} />
    </div>
  );
};
export default observer(Administrators);
