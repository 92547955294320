export const API = {
  // 查询角色类型
  queryRoleTypePage: '/quan/iac/role/page',
  // 查询管理员
  queryAdminPage: '/quan/sys/user/list/page',
  // 新增管理员
  saveAdmin: '/quan/sys/user/add',
  // 编辑管理员
  editAdmin: '/quan/sys/user/edit',
  // 删除管理员
  deleteAdmin: '/quan/sys/user/delete',
  // 编辑管理员状态
  editAdminStatus: '/quan/sys/user/status',
  // 重置密码
  resetPassword: '/quan/sys/user/modify/password',
};
